import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';
import Link from '../../../components/Link';

export default function YardCare() {
  const {
    props: { leaf_blower_image: leafBlowerImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Yard Care Equipment"
      img={leafBlowerImage}
      imgAlt="Image of an electric leaf blower"
      showProductsButton={false}
    >
      <Typography mb={2}>
        Don't settle for outdated, polluting yard equipment. Upgrade to electric and enjoy a
        cleaner, quieter, and easier experience. Electric leaf blowers, lawn mowers, trimmers, and
        chainsaws provide the same power as gas models, but with zero emissions, minimal
        maintenance, and much less noise.
      </Typography>
      <Typography>
        Gas-powered yard equipment causes significant air pollution. Discover the health impacts
        with some surprising facts from the{' '}
        <Link
          href="https://ww2.arb.ca.gov/resources/fact-sheets/sore-small-engine-fact-sheet"
          sx={{ color: 'green.400' }}
          external
        >
          California Air Resources Board
        </Link>
        .
      </Typography>
    </ApplianceTab>
  );
}
