import { Box, styled } from '@mui/material';

const IFrame = styled('iframe')({
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  position: 'absolute',
});

function EmbeddedVideo({ videoUrl, title, height, width }) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        pb: '56.25%',
        position: 'relative',
        height: 0,
      }}
    >
      <IFrame
        height={height}
        width={width}
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </Box>
  );
}

export default EmbeddedVideo;
