import { Box, Container, Typography } from '@mui/material';
import Link from '../../../components/Link';
import { LeftArrowIcon } from '../../../assets/icons/icons';
import Breadcrumbs from '../../../components/Breadcrumbs';

export default function CompareHeader({ heading }) {
  return (
    <Container maxWidth="xl">
      <Box pb={2}>
        <Breadcrumbs />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mb={1} mt={2}>
        <Typography display="flex" variant="h1" flexDirection="column">
          <Typography
            variant="h2"
            component="span"
            mb={2}
            textAlign="center"
            fontWeight={600}
            fontSize={{ xs: '1.5rem', sm: '3rem' }}
          >
            {heading}
          </Typography>
          <Typography
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            mb={{ xs: 0, md: 5 }}
            textAlign="center"
            fontWeight={400}
          >
            All products are{' '}
            <Typography
              component="span"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              color="blue.500"
              fontWeight={600}
            >
              ENERGY STAR®
            </Typography>{' '}
            certified where applicable.
          </Typography>
        </Typography>
      </Box>
    </Container>
  );
}
