import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';
import Link from '../../../components/Link';

export default function ElectricDryer() {
  const {
    props: { dryer_image: dryerImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Electric Dryer"
      img={dryerImage}
      imgAlt="Image of an electric dryer"
      buttonLink="/products/dryers"
      buttonText="Shop Electric Dryers"
    >
      <Typography mb={2}>
        When thinking about home energy use, the dryer might not be the first appliance that comes
        to mind. However, the average American family washes about 300 loads of laundry annually,
        and the energy use quickly adds up. Switching to an electric dryer is smart - they're safer,
        more affordable, and easier to install than gas dryers.
      </Typography>
      <Typography>
        Learn more about the benefits of electric dryers with{' '}
        <Link
          href="https://svcleanenergy.org/wp-content/uploads/2020/02/Electric-Dryer-Digital-The-Baiscs_09.16.2020.pdf"
          sx={{ color: 'green.400' }}
          external
        >
          The Basics
        </Link>
        .
      </Typography>
    </ApplianceTab>
  );
}
