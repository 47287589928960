import SvceButton from './SvceButton';
import { Box, Typography } from '@mui/material';
import { Link as InertiaLink } from '@inertiajs/react';
import { getDetailsUrl } from '../utils/urlFunctions';
import Imgix from 'react-imgix';

export default function CondensedResourceCard({ resource, setIsDrawerOpen }) {
  const { id, name, shortDescription, resourceCardCta, baseImgixUrl, imgixOpts } = resource;

  const url = getDetailsUrl({ id, type: 'resource' });

  const knownDimensions = { h: 153, w: 358 };
  const mergedParams = { ...imgixOpts, ...knownDimensions, dpr: window.devicePixelRatio };

  return (
    <Box
      component={InertiaLink}
      href={url}
      preserveState={true}
      preserveScroll={true}
      onClick={() => setIsDrawerOpen(true)}
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'gray.300',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'all 200ms ease',
        textDecoration: 'none',
        color: 'inherit',
        '&:hover, &:focus': {
          boxShadow: 3,
        },
      }}
    >
      <Box
        component={Imgix}
        src={baseImgixUrl}
        sizes="358px"
        imgixParams={mergedParams}
        disableSrcSet={false}
        htmlAttributes={{ alt: '', role: 'presentation', 'aria-hidden': true }}
        flexShrink={0}
        alignSelf="stretch"
        width="100%"
        height={153}
        maxHeight={153}
        sx={{
          objectFit: 'cover',
        }}
      />
      <Box p={2} flexGrow={1} display="flex" flexDirection="column">
        <Typography fontSize="1.25rem" fontWeight={600} lineHeight="30px" mb={1}>
          {name}
        </Typography>
        <Typography color="svce.darkGrey" mb={1} flexGrow={1}>
          {shortDescription}
        </Typography>
        <SvceButton>{resourceCardCta || 'View'}</SvceButton>
      </Box>
    </Box>
  );
}
