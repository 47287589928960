import React, { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeaderMenu from './HeaderMenu';
import { Box, Container, useTheme } from '@mui/material';
import Link from '../../components/Link';
import SearchBar from '../../components/SearchBar';
import SearchModal from '../../components/SearchModal/SearchModal';
import { HamburgerIcon, MenuCloseIcon, SearchIcon } from '../../assets/icons/icons';
import Logo from '../../assets/images/logo.png';
import MobileNavMenu from './MobileNavMenu';

const HeaderLink = ({ text, href }) => {
  const theme = useTheme();
  return (
    <Link href={href} external>
      <Typography
        mt="10px"
        mb="10px"
        sx={{
          fontSize: '13px',
          lineHeight: '20.3px',
          color: theme.palette.common.white,
        }}
      >
        {text}
      </Typography>
    </Link>
  );
};

const ABOUT_US_MENU_ITEMS = [
  {
    title: 'Our Story',
    link: 'https://svcleanenergy.org/about/',
    isExternal: true,
  },
  {
    title: 'Who We Are',
    subItems: [
      { title: 'Board of Directors', link: 'https://svcleanenergy.org/board/', isExternal: true },
      { title: 'Staff', link: 'https://svcleanenergy.org/team/', isExternal: true },
      { title: 'Join Our Team', link: 'https://svcleanenergy.org/jobs/', isExternal: true },
    ],
  },
  {
    title: 'Service Area',
    link: 'https://svcleanenergy.org/service-area/',
    isExternal: true,
  },
  {
    title: 'How It Works',
    link: 'https://svcleanenergy.org/how-it-works/',
    isExternal: true,
  },
  {
    title: 'Renewable Projects',
    link: 'https://svcleanenergy.org/power-sources/',
    isExternal: true,
  },
];

const EHUB_MENU_ITEMS = [
  {
    title: 'Residents',
    subItems: [
      { title: 'Why Go Electric?', link: '/' },
      { title: 'Rebates & Incentives', link: '/incentives' },
      { title: 'Get Advice', link: 'https://goelectric.svcleanenergy.org/', isExternal: true },
    ],
  },
  {
    title: 'Businesses & Contractors',
    subItems: [
      { title: 'Rebates & Incentives', link: '/business' },
      {
        title: 'Contractor Training',
        link: 'https://svcleanenergy.org/futurefit-fundamentals/',
        isExternal: true,
      },
    ],
  },
];

const RATES_MENU_ITEMS = [
  {
    title: 'Service Plans',
    link: 'https://svcleanenergy.org/plans/',
    isExternal: true,
  },
  {
    title: 'Electricity Rates',
    subItems: [
      {
        title: 'Residents',
        link: 'https://svcleanenergy.org/residential-rates/',
        isExternal: true,
      },
      {
        title: 'Businesses',
        link: 'https://svcleanenergy.org/commercial-rates/',
        isExternal: true,
      },
      { title: 'Rooftop Solar', link: 'https://svcleanenergy.org/solar/', isExternal: true },
    ],
  },
  {
    title: 'Understanding Your Bill',
    link: 'https://svcleanenergy.org/your-bill/',
    isExternal: true,
  },
  {
    title: 'Payment Assistance',
    link: 'https://svcleanenergy.org/payment-assistance/',
    isExternal: true,
  },
  {
    title: 'Terms & Conditions',
    link: 'https://svcleanenergy.org/conditions/',
    isExternal: true,
  },
];

export const Header = () => {
  const theme = useTheme();

  const headerRef = useRef(null);
  const modalRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchButtonRef = useRef(null);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [mobileNavMenuAnchorEl, setMobileNavMenuAnchorEl] = useState(null);

  // close search modal and mobile nav menu on outside click
  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      const isModalClick = modalRef.current && modalRef.current.contains(e.target);
      const isSearchBarClick = searchBarRef.current && searchBarRef.current.contains(e.target);
      const isSearchButtonClick =
        searchButtonRef.current && searchButtonRef.current.contains(e.target);

      if (!isModalClick && !isSearchBarClick && !isSearchButtonClick) {
        setIsSearchModalOpen(false);
      }
    };
    document.addEventListener('click', closeModalOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeModalOnOutsideClick);
    };
  }, []);

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" mr={2}>
        <HeaderLink href="https://svcleanenergy.org/plans-policies-reports/" text="Key Documents" />
        <HeaderLink href="https://svcleanenergy.org/meetings/" text="Meetings & Agendas" />
      </Stack>
      <Box
        component="header"
        ref={headerRef}
        pt={3}
        pb={2}
        sx={{
          backgroundColor: theme.palette.common.white,
          bgcolor: '#faf7f5',
          boxShadow: theme.boxShadows.header,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Link href="https://svcleanenergy.org/" external>
            <Box component="img" alt="SVCE Logo" src={Logo} height={54} width={248} />
          </Link>
          <Box display="flex" alignItems="center">
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <HeaderMenu title="About Us" items={ABOUT_US_MENU_ITEMS} />
              <HeaderMenu title="Rebates & Offers" items={EHUB_MENU_ITEMS} />
              <HeaderMenu title="Rates & Billing" items={RATES_MENU_ITEMS} />
            </Stack>

            {/* small screen search button */}
            <Button
              ref={searchButtonRef}
              data-testid="mobile-search-button"
              aria-label="Search products and resources"
              aria-expanded={isSearchModalOpen ? 'true' : 'false'}
              onClick={() => setIsSearchModalOpen((prev) => !prev)}
              sx={{
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%',
                p: 1,
                m: 0,
              }}
            >
              <SearchIcon />
            </Button>

            <Box display={{ xs: 'none', md: 'block' }} ml={2}>
              <SearchBar ref={searchBarRef} onFocus={() => setIsSearchModalOpen(true)} />
            </Box>

            <Box display={{ xs: 'block', md: 'none' }}>
              <Button
                onClick={() => setMobileNavMenuAnchorEl(headerRef.current)}
                sx={{
                  minWidth: 'auto',
                  pl: 1,
                }}
                aria-controls="mobile-nav-menu"
                aria-label={mobileNavMenuAnchorEl ? 'Close nav menu' : 'Open nav menu'}
                aria-expanded={mobileNavMenuAnchorEl ? 'true' : 'false'}
              >
                <Box display={mobileNavMenuAnchorEl ? 'flex' : 'none'} alignItems="center">
                  <MenuCloseIcon />
                </Box>
                <Box display={mobileNavMenuAnchorEl ? 'none' : 'flex'} alignItems="center">
                  <HamburgerIcon />
                </Box>
              </Button>

              <MobileNavMenu
                open={Boolean(mobileNavMenuAnchorEl)}
                anchorEl={mobileNavMenuAnchorEl}
                onClose={() => setMobileNavMenuAnchorEl(null)}
              />
            </Box>
          </Box>

          <SearchModal
            ref={modalRef}
            isOpen={isSearchModalOpen}
            onClose={() => setIsSearchModalOpen(false)}
          />
        </Container>
      </Box>
    </Stack>
  );
};
