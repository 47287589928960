import Typography from '@mui/material/Typography';
import { Box, Container, Grid } from '@mui/material';
import CondensedResourceCard from '../../../components/CondensedResourceCard';
import useDetailsDrawer from '../../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../../components/DetailsDrawer';

export default function Resources({ resources, copy: { title, subtitle1, subtitle2 } }) {
  const {
    isOpen: isDrawerOpen,
    setIsOpen: setIsDrawerOpen,
    detailedItem,
    detailedItemType,
    clearDetailedItem,
  } = useDetailsDrawer();

  return (
    <Box mt={{ xs: 0, md: 6 }}>
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Typography
          variant="h2"
          fontSize={{ xs: '1.25rem', md: '3rem' }}
          fontWeight={600}
          mb={2}
          textAlign="center"
        >
          {title.pre_emphasis}{' '}
          <Typography
            component="span"
            fontSize={{ xs: '1.25rem', md: '3rem' }}
            fontWeight={600}
            color="primary.main"
          >
            {title.emphasis}
          </Typography>
          {title.post_emphasis}
        </Typography>

        <Typography fontSize="1.125rem" mb={2} textAlign={{ xs: 'center', md: 'left' }}>
          {subtitle1}
        </Typography>
        <Typography fontSize="1.125rem" textAlign={{ xs: 'center', md: 'left' }}>
          {subtitle2}
        </Typography>
      </Container>

      <Container maxWidth="xl" sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          {resources.map((resource) => (
            <Grid item xs={12} sm={6} md={3} key={resource.id}>
              <CondensedResourceCard resource={resource} setIsDrawerOpen={setIsDrawerOpen} />
            </Grid>
          ))}
        </Grid>
      </Container>

      <DetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        detailedItemType={detailedItemType}
        detailedItem={detailedItem}
        clearDetailedItem={clearDetailedItem}
      />
    </Box>
  );
}
