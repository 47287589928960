import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { theme } from '../config/theme';
import SvceLayout from '../layouts/SvceLayout';
import axios from 'axios';
import ReactGA from 'react-ga4';
import './application.css'

document.addEventListener('DOMContentLoaded', () => {
  ReactGA.initialize('G-S5VW766YYM', {
    nonce: document.querySelector('meta[name="csp-nonce"]')?.content,
  });

  createInertiaApp({
    resolve: (name) => {
      const pages = import.meta.glob('../pages/**/*.jsx', { eager: true });
      const Page = pages[`../pages/${name}/index.jsx`];
      Page.default.layout = Page.default.layout || SvceLayout;
      return Page;
    },
    setup({ el, App, props }) {
      createRoot(el).render(
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App {...props} />
        </ThemeProvider>,
      );
    },
  });

  const csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  }
});
