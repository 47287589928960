import { usePage } from '@inertiajs/react';
import { Box } from '@mui/material';
import React from 'react';
import Link from './Link';
import { HomeIcon, RightArrowIcon } from '../assets/icons/icons';

const ariaProps = { 'aria-hidden': 'true', focusable: 'false' };

export default function Breadcrumbs() {
  const {
    props: { breadcrumbs = [] },
  } = usePage();

  if (!breadcrumbs.length) return null;

  const isLastBreadcrumb = (idx) => idx === breadcrumbs.length - 1;

  return (
    <Box display="flex" alignItems="center">
      {breadcrumbs.map(({ name, url }, idx) => (
        <Box key={idx} display="flex" alignItems="center">
          <Link
            href={url}
            sx={{
              py: 0.5,
              px: 1,
              color: 'svce.darkGrey',
              fontWeight: 600,
              fontSize: { xs: '0.875rem', md: '1rem' },
              textDecoration: 'none',
              borderRadius: '6px',
              backgroundColor: isLastBreadcrumb(idx) ? 'gray.100' : 'common.white',
              pointerEvents: isLastBreadcrumb(idx) ? 'none' : 'auto',
            }}
            disabled={isLastBreadcrumb(idx)}
          >
            <Box display="flex" alignItems="center">
              {url === '/' && (
                <Box mr={1} display="flex" alignItems="center">
                  <HomeIcon {...ariaProps} />
                </Box>
              )}
              {name}
            </Box>
          </Link>
          {!isLastBreadcrumb(idx) && (
            <Box mx={{ xs: 0.5, md: 1 }}>
              <RightArrowIcon fill="#D0D5DD" {...ariaProps} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
