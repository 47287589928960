import { Box, Container, Typography } from '@mui/material';
import Accordion from '../../../components/Accordion';
import { usePage } from '@inertiajs/react';
import Link from '../../../components/Link';

export default function Faq() {
  const {
    props: { emissions_pie_chart_image: emissionsPieChartImage },
  } = usePage();

  return (
    <Container>
      <Typography variant="h2" fontSize="3rem" mb={2} textAlign="center">
        F.A.Q.
      </Typography>
      <Typography mb={2} textAlign="center">
        These are some of the most common questions we receive from the community. We have plenty of
        resources to share if you can't find something here. Reach out to us. We're here to help.
      </Typography>

      <Accordion title="What is clean energy and why is it important?">
        <Typography>
          Clean energy comes from sources that do not pollute the atmosphere. Silicon Valley Clean
          Energy purchases electricity from wind, solar, geothermal, large hydropower plants and
          other clean resources.
        </Typography>
      </Accordion>

      <Accordion title="What are greenhouse gasses (GHGs)?">
        <Typography mb={2}>
          A GHG is any gaseous compound in the atmosphere that is capable of absorbing infrared
          radiation, thereby trapping and holding heat in the atmosphere. By increasing the heat in
          the atmosphere GHGs are responsible for the greenhouse effect, which ultimately leads to
          climate change.
        </Typography>
        <Typography>
          The most significant GHGs are carbon dioxide, methane and nitrous oxide, according to the
          Environmental Protection Agency.
        </Typography>
      </Accordion>

      <Accordion title="What contributes to GHGs in our community?">
        <Typography mb={2}>
          Burning fossil fuels, like gas and oil, releases carbon dioxide (CO2). In our region,
          about 55% of emissions come from gas-powered vehicles, and most of the remaining emissions
          come from burning fossil fuels in homes and buildings.*
        </Typography>
        <Box
          component="img"
          src={emissionsPieChartImage}
          alt="Chart showing emissions sources"
          sx={{
            height: { xs: 'auto', sm: 300 },
            width: { xs: '100%', sm: 590 },
            display: 'block',
            margin: '0 auto',
            mb: 2,
            bgcolor: 'gray.600',
            borderRadius: 8,
            p: 2,
          }}
        />
        <Typography>
          *Sources: PG&E electricity and gas data; CARB; Metropolitan Transportation Commission;
          California DMV.
        </Typography>
      </Accordion>

      <Accordion title="What can I do about climate change?">
        <Typography mb={2}>
          As a Silicon Valley Clean Energy customer, you have already committed to reducing our
          region's emissions by receiving clean electricity and investing in initiatives to lower
          local pollution levels. If you're ready to reduce emissions even more, consider switching
          your gas-powered car to electric. Emissions from internal combustion engine cars are the
          number one air pollutant in Silicon Valley and across California.
        </Typography>
        <Typography mb={2}>
          From there, you can reduce emissions even further by switching to high-efficiency,
          electric home appliances. For example, replacing a gas-powered water heater to a heat pump
          water heater will eliminate about 50% of the emissions that come from your home and can
          save you money in the long run.
        </Typography>
        <Typography>
          To learn more about reducing your impact on the climate, and maybe save some money too, go
          to{' '}
          <a
            href="https://coolcalifornia.arb.ca.gov/calculator-households-individuals"
            target="_blank"
            rel="noopener noreferrer"
          >
            CoolCalifornia.org
          </a>
          , a resource funded by California state government and non-profit agencies.
        </Typography>
      </Accordion>

      <Accordion title="Is natural gas clean energy?">
        <Typography mb={2}>
          No, natural gas is not clean energy. It's toxic to people and is a large source of
          greenhouse gas emissions that is causing climate change.
        </Typography>
        <Typography>
          Natural gas is commonly used to power gas appliances in California, emitting carbon
          monoxide and other chemicals into our homes and communities. Upgrading from gas appliances
          to high-efficiency electric ones is healthier and cleaner for all of us.
        </Typography>
      </Accordion>

      <Accordion title="Isn't clean electricity expensive?">
        <Typography>
          Onshore wind and solar are now the cheapest sources of power generation in nearly all
          major economies in the world.{' '}
          <Link
            external
            href="https://www.pbs.org/newshour/science/electricity-generated-from-renewables-surpassed-coal-in-the-u-s-last-year"
          >
            PBS NewsHour, August 16, 2023: Electricity generated from renewables surpassed coal in
            the U.S. last year.
          </Link>
        </Typography>
      </Accordion>

      <Accordion title="Can clean energy really meet our community's electricity needs?">
        <Typography>
          Today, about half of California's energy is sourced from renewable energy and large
          hydroelectric plants. This number will continue to grow as additional sources of clean
          power become more abundant and battery use (storing energy during the day and transmitting
          electricity to the grid when needed) increases.
        </Typography>
      </Accordion>

      <Accordion title="When is the most cost-effective time to consider installing efficient, electric appliances?">
        <Box component="ol" pl={2.5}>
          <Box component="li" mb={2}>
            Immediately before your existing appliance no longer functions. Yes – this is hard to
            predict. But, most appliances have average life spans. For example, water heaters
            typically last between 8-12 years. So, if your water heater is between 8-12 years old,
            it's nearing the end of its useful life. Now is the time to consider replacing it with a
            high- efficiency electric water heater to prevent being without hot water.
          </Box>
          <Box component="li" mb={2}>
            When you're planning a home remodel or new construction. To switch to some
            high-efficiency home appliances your home may need an electrical panel upgrade to 200
            amps, however, this can often be avoided with new technologies. During your remodel or
            new construction, your contractor will be able to plan accordingly.
          </Box>
          <Box component="li" mb={2}>
            If you run your home appliances on propane. Propane is a fossil fuel and is trucked to
            homes. It is much more expensive than electricity. Propane users will see immediate
            savings when switching to electric appliances while reducing emissions and increasing
            safety.
          </Box>
          <Box component="li" mb={2}>
            If you are looking to add air conditioning to your home, look for a heat pump unit that
            can perform both summer cooling and winter heating.
          </Box>
          <Box component="li" mb={2}>
            If your current water heater is an electric resistance unit (about 5% of customers have
            these), an electric heat pump water heater costs only 30 cents to make the same amount
            of hot water compared to a full dollar for electric resistance units.
          </Box>
        </Box>
      </Accordion>

      <Accordion title="Can the grid handle the installation of all of these new electrical appliances?">
        <Typography>
          Yes. There is time to continue to make the necessary investments to add supply and ensure
          reliability as more communities and customers make the transition to all-electric
          buildings. Grid investments are being made by PG&E and supported by the State and Federal
          government on a daily basis. In addition, the installation of a heat pump heating and
          cooling system can actually reduce grid demand if it's replacing an older, inefficient air
          conditioning unit. Lastly, electric appliances are flexible which means they can be
          controlled and timed to help reduce strain on the grid at critical times – like during
          extreme weather events.
        </Typography>
      </Accordion>

      <Accordion title="What happens in power outages? I want appliances that will work when the grid goes down.">
        <Typography>
          Modern gas and electric appliances have similar performance challenges when the power goes
          out. Gas furnaces for example require an electric fan to operate. Like gas water heaters,
          electric heat pumps store hot water, even when the power is out.
        </Typography>
      </Accordion>

      <Accordion title="Do heat pumps work in California?">
        <Typography>
          Heat pumps are an excellent choice for a residential heating, ventilation, and air
          conditioning (HVAC) system and water heating, especially in moderate climates like
          California. Heat pumps are common worldwide, including in areas with much colder climates.
        </Typography>
      </Accordion>

      <Accordion title="Are induction stoves healthier? Any other advantages?">
        <Typography>
          Yes. Induction cooktops, unlike gas or traditional electric cooktops, do not use gas lines
          or electric resistance cooking elements. This minimizes the chances of causing fires.
          Plus, induction stovetops eliminate toxic pollutants like nitrogen dioxide (NO2) that are
          given off from the open flame on gas stovetops. And, induction cooktops can boil water
          twice as fast as natural gas or propane.
        </Typography>
      </Accordion>

      <Accordion title="Don't I need special cookware for an induction cooktop?">
        <Typography>
          Induction cooktops can be used with common cookware that is made of ferrous metals, such
          as steel or cast iron. If a magnet sticks to your cookware, it will work on an induction
          stovetop.
        </Typography>
      </Accordion>

      <Accordion title="What is a smart thermostat and will it save me money?">
        <Typography>
          A smart thermostat is an electric appliance that can be controlled with a phone or other
          internet-connected device. They typically allow you to schedule your desired temperature
          settings and can be incorporated into home automation systems. Some thermostats can learn
          your heating and cooling preferences, have diagnostic features to detect problems with
          your HVAC system, remind you when it's time to change an air filter, and provide details
          regarding energy consumption. Smart thermostats have more features than the more common
          “programmable thermostat” and, on average, reduce customers' energy use and energy bill.
        </Typography>
      </Accordion>

      <Accordion title="Is installing a solar system expensive?">
        <Typography>
          For many solar customers, solar costs less than continuing to pay their full utility bill
          and eventually provides a return on investment. And the cost of the solar system
          components continues to drop and federal tax credits are still available. However, each
          home has unique features that will influence the value and cost of installing a solar
          system. Things like sun exposure, pitch of roof, or whether you choose to buy or lease
          your solar system, can impact the overall cost.
        </Typography>
      </Accordion>

      <Accordion title="Doesn't solar only work when the sun shines?" borderBottom={false}>
        <Typography>
          While solar only generates during the day, solar energy can be stored for nighttime use.
          Pairing batteries with solar panels allows the homeowner to store excess solar power in
          the batteries for later use when the sun isn't shining. Batteries also provide backup
          power if there's a power outage in your area. Adding batteries also helps improve the
          economics of the total system cost as you can use the free solar energy generated during
          the day during peak evening hours when electricity costs more.
        </Typography>
      </Accordion>
    </Container>
  );
}
