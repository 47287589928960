import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';
import Link from '../../../components/Link';

export default function InductionCooking() {
  const {
    props: { induction_stove_image: inductionStoveImage, brede_image: bredeImage },
  } = usePage();
  return (
    <ApplianceTab
      heading="Induction Cooking"
      img={inductionStoveImage}
      imgAlt="Image of an induction stove"
      testimonial="“My wife and her mom had read a few articles about the indoor emissions associated with gas ranges and were concerned about inhaling toxins.”"
      testimonialName="Brede - Sunnyvale, CA"
      testimonialImg={bredeImage}
      buttonLink="/home-upgrades"
      buttonText="Home Upgrades"
    >
      <Typography mb={2}>
        Induction cooktops use cutting-edge electromagnetic technology to heat your cookware
        directly, offering precise temperature control and an easy-to-clean surface. Plus, they boil
        water two to three times faster than gas stoves. Unlike gas cooktops, which release harmful
        pollutants like{' '}
        <Link
          href="https://www.scientificamerican.com/article/the-health-risks-of-gas-stoves-explained/"
          sx={{ color: 'green.400' }}
          external
        >
          nitrogen dioxide (NO2)
        </Link>{' '}
        and formaldehyde, induction cooktops produce no excess heat and keep your indoor air clean
        and safe. Learn more about induction cooking with{' '}
        <Link
          href="https://svcleanenergy.org/wp-content/uploads/Induction_Cooktop._Basics_digital.pdf"
          sx={{ color: 'green.400' }}
          external
        >
          The Basics
        </Link>
        .
      </Typography>
      <Typography>
        Explore{' '}
        <Link href="/home-upgrades" sx={{ color: 'green.400' }}>
          Home Upgrades
        </Link>{' '}
        to choose incentives, from rebates to full-service installation support, for induction
        cooktops.
      </Typography>
    </ApplianceTab>
  );
}
