import { Box, Grid, Typography } from '@mui/material';
import { times } from 'lodash';
import { forwardRef } from 'react';
import {
  ExternalLinkIcon,
  StarIconEmpty,
  StarIconFilled,
  StarIconHalfFilled,
} from '../../assets/icons/icons';
import { formatAsThousands } from '../../utils/formatters';
import ButtonLink from '../ButtonLink';

const ReviewStars = ({ stars }) => {
  const roundedHalfStars = Math.round(stars * 2) / 2;

  const ariaProps = {
    'aria-hidden': 'true',
    focusable: 'false',
  };

  const getStarIconVariant = (i) => {
    if (roundedHalfStars - i === 0.5) return <StarIconHalfFilled key={i} {...ariaProps} />;
    if (roundedHalfStars - 1 >= i) return <StarIconFilled key={i} {...ariaProps} />;
    return <StarIconEmpty key={i} {...ariaProps} />;
  };

  return stars ? (
    <Box display="flex" alignItems="center" role="img" aria-label={`${stars} out of 5 stars`}>
      {times(5, (idx) => getStarIconVariant(idx))}
    </Box>
  ) : null;
};

const ReviewCount = ({ count }) => {
  return count ? <Typography ml={0.5}>({formatAsThousands(count)})</Typography> : null;
};

const Offer = ({
  offer: { name, salePrice, logoImageUrl, reviewAverage, reviewCount, offerUrl },
}) => (
  <Box
    component="li"
    pl={1}
    py={1}
    borderTop="1px solid"
    sx={{
      borderColor: 'gray.200',
      '&:last-child': {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
    }}
  >
    <Grid container columnSpacing={1} alignItems="center">
      <Grid item xs={5} sm={4} display="flex" alignItems="center">
        <Box component="img" src={logoImageUrl} alt={name} maxHeight={45} maxWidth={150} />
      </Grid>
      <Grid item sm={4} flexGrow={1} display={{ xs: 'none', sm: 'flex' }} alignItems="center">
        <ReviewStars stars={reviewAverage} />
        <ReviewCount count={reviewCount} />
      </Grid>
      <Grid item xs={3} sm={2}>
        <Typography
          variant="h4"
          component="span"
          display="block"
          fontWeight="bold"
          fontSize="1.125rem"
          textAlign={{ xs: 'right', sm: 'left' }}
          width="100%"
          pr={{ xs: 1, sm: 0 }}
        >
          {salePrice}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <ButtonLink
          external
          href={offerUrl}
          fullWidth
          sx={{ py: 1 }}
          aria-label={`Buy from ${name} (opens in new tab)`}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography fontWeight="bold" textTransform="uppercase" mr={1}>
              Buy
            </Typography>
            <Box
              flexShrink={0}
              display="flex"
              alignItems="center"
              aria-hidden="true"
              focusable="false"
            >
              <ExternalLinkIcon />
            </Box>
          </Box>
        </ButtonLink>
      </Grid>
    </Grid>
  </Box>
);

const ProductDetailsOffers = forwardRef(({ product }, ref) => {
  const { offers, reviewCount, reviewAverage } = product;

  return (
    <Box display="flex" flexDirection="column" mb={5} ref={ref}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        mb={2.5}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Typography
          variant="h4"
          component="h3"
          fontSize="1.125rem"
          fontWeight="bold"
          mb={{ xs: 1, sm: 0 }}
        >
          Today&apos;s Online Offers
        </Typography>
        <Box display="flex" alignItems="center">
          <ReviewStars stars={reviewAverage} />
          <Typography variant="h4" fontSize="1rem" fontWeight={600} component="span" ml={1.5}>
            ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
          </Typography>
        </Box>
      </Box>

      <Box component="ul" m={0} p={0} sx={{ listStyle: 'none' }}>
        {offers.map((offer) => (
          <Offer key={offer.handle} offer={offer} />
        ))}
      </Box>
    </Box>
  );
});

// needed for arrow function declaration with forwardRef
// https://stackoverflow.com/questions/59716140/using-forwardref-with-proptypes-and-eslint
ProductDetailsOffers.displayName = 'ProductDetailsOffers';

export default ProductDetailsOffers;
