import { Box, Container, Typography } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import Faq from './Faq';
import HelpLinks from './HelpLinks';
import Appliances from './Appliances';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { useRef } from 'react';
import SvceButton from '../../../components/SvceButton';
import Link from '../../../components/Link';

export default function ElectricAtHome({ hero_image: heroImage }) {
  const tabsRef = useRef();

  const handleLearnMoreClick = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box py={{ xs: 0, md: 3, lg: 6 }}>
      <Box display="flex" flexDirection="column">
        <Box order={{ xs: 2, md: 1 }}>
          <Container maxWidth="xl">
            <Box pt={{ xs: 2, md: 0 }} py={3}>
              <Breadcrumbs />
            </Box>

            <HeadingWithFlair>Electric at Home</HeadingWithFlair>
            <SvceButton
              onClick={handleLearnMoreClick}
              sx={{ width: { xs: '100%', md: 230 }, mb: { xs: 3, md: 7 } }}
            >
              Learn More
            </SvceButton>
          </Container>
        </Box>

        <Box width="100%" order={{ xs: 1, md: 2 }}>
          <Box
            component="img"
            alt=""
            src={heroImage}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
              maxHeight: { xs: 240, md: 545 },
              borderTopLeftRadius: { xs: 50, md: 0 },
              borderTopRightRadius: { xs: 50, md: 0 },
            }}
          />
        </Box>
      </Box>

      <Container maxWidth="md">
        <Box my={{ xs: 3, md: 6 }}>
          <Typography variant="h2" fontWeight={600} fontSize="2rem">
            Why home appliances matter.
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }} mt={2}>
            Nearly a quarter of Silicon Valley's air pollution comes from fossil fuels used in homes
            for heating water, indoor air, dryers, and stoves. Burning fossil fuels, like methane
            gas and propane, harms air quality and drives climate change. Upgrading gas appliances
            and equipment to electric ones reduces pollution, improves energy efficiency, and
            enhances home safety and comfort. Discover the right electric solutions for your home
            and make a positive impact on the environment and a better future for our community.
          </Typography>
        </Box>
      </Container>

      <Box ref={tabsRef}>
        <Appliances />
      </Box>

      <Container maxWidth="md">
        <Box my={{ xs: 3, md: 6 }}>
          <Typography variant="h2" fontWeight={600} fontSize="2rem">
            Go even further on your all-electric journey
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }} mt={2}>
            If you&apos;re transitioning to electric technologies like heat pumps and induction
            stoves, installing solar+battery systems and upgrading to an electric vehicle (EV) could
            be a logical choice. By aligning your energy-intensive appliances and EV charging with
            daytime solar production and using battery storage for evening use, you can optimize
            energy benefits, cut emissions, and potentially lower your electric bill.{' '}
            <Link
              href="https://svcleanenergy.org/electric-showcase-awards-23"
              sx={{ color: 'green.400' }}
              external
            >
              See how your neighbors are going all electric.
            </Link>
          </Typography>
        </Box>
      </Container>

      <HelpLinks />

      <Faq />
    </Box>
  );
}
