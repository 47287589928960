import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { DownArrowIcon } from '../../assets/icons/icons';
import { NestedDropdown } from 'mui-nested-menu';
import { router } from '@inertiajs/react';

export default function HeaderMenu({ title, items = [] }) {
  const theme = useTheme();

  const handleLinkClick = ({ link, isExternal }) => {
    if (isExternal) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      router.visit(link, { preserveState: true, preserveScroll: true });
    }
  };

  const MENU_ITEM_SX = {
    py: 2.5,
    px: 3,
    transition: 'all 300ms ease',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 600,
    },
    '&:hover,&:focus-visible': {
      bgcolor: 'primary.main',
      color: 'common.white',
    },
  };

  const menuItemsData = {
    label: title,
    component: 'a',
    items: items.map(({ title, link, isExternal, subItems = [] }) => ({
      label: title,
      callback: () => handleLinkClick({ link, isExternal }),
      sx: MENU_ITEM_SX,
      items: subItems.map(
        ({ title: subItemTitle, link: subItemLink, isExternal: subItemIsExternal }) => ({
          label: subItemTitle,
          callback: () => handleLinkClick({ link: subItemLink, isExternal: subItemIsExternal }),
          sx: MENU_ITEM_SX,
        }),
      ),
    })),
  };

  return (
    <>
      <NestedDropdown
        menuItemsData={menuItemsData}
        ButtonProps={{
          endIcon: <DownArrowIcon fill={theme.palette.primary.main} />,
          sx: {
            fontWeight: 600,
            color: 'gray.900',
            mx: { lg: 0.5 },
          },
        }}
        MenuProps={{
          sx: { '& .MuiList-root': { py: 0 } },
        }}
      />
    </>
  );
}
