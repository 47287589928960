import Link from '../../components/Link';
import Accordion from '../../components/Accordion';
import { Box, Popover } from '@mui/material';

const NavLink = ({ children, ...props }) => (
  <Link sx={{ fontWeight: 600, color: 'green.700', py: 0.5, mb: 0.5 }} {...props}>
    {children}
  </Link>
);

export default function MobileNavMenu({ anchorEl, onClose, open }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="mobile-nav-menu"
      marginThreshold={0}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: '100%',
          maxWidth: '100%',
          left: 0,
          right: 0,
          px: 5,
          py: 4,
        },
      }}
    >
      <Accordion title="ABOUT US">
        <Box display="flex" flexDirection="column">
          <NavLink external href="https://svcleanenergy.org/about/">
            Our Story
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/board/">
            Board of Directors
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/team">
            Staff
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/jobs/">
            Join Our Team
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/service-area/">
            Service Area
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/how-it-works/">
            How It Works
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/power-sources/">
            Renewable Projects
          </NavLink>
        </Box>
      </Accordion>
      <Accordion title="REBATES & OFFERS">
        <Box display="flex" flexDirection="column">
          <NavLink href="/" onClick={onClose}>
            Why Go Electric?
          </NavLink>
          <NavLink href="/incentives" onClick={onClose}>
            Residents - Rebates & Incentives
          </NavLink>
          <NavLink external href="https://goelectric.svcleanenergy.org/">
            Get Advice
          </NavLink>
          <NavLink href="/business" onClick={onClose}>
            Businesses - Rebates & Incentives
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/futurefit-fundamentals/">
            Contractor Training
          </NavLink>
        </Box>
      </Accordion>
      <Accordion title="RATES & BILLING" borderBottom={false}>
        <Box display="flex" flexDirection="column">
          <NavLink external href="https://svcleanenergy.org/plans/">
            Service Plans
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/residential-rates/">
            Residents - Electricity Rates
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/commercial-rates/">
            Businesses - Electricity Rates
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/solar/">
            Rooftop Solar
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/your-bill/">
            Understanding Your Bill
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/payment-assistance/">
            Payment Assistance
          </NavLink>
          <NavLink external href="https://svcleanenergy.org/conditions/">
            Terms & Conditions
          </NavLink>
        </Box>
      </Accordion>
    </Popover>
  );
}
