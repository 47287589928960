import { usePage } from '@inertiajs/react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import ButtonLink from '../../../components/ButtonLink';

export default function HelpLinks() {
  const {
    props: {
      solar_panels_image: solarPanelsImage,
      ev_image: evImage,
      computer_image: computerImage,
    },
  } = usePage();

  return (
    <Container maxWidth="xl" sx={{ mb: { xs: 5, md: 10 } }}>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          order={{ xs: 2, md: 1 }}
          mb={{ xs: 2, md: 0 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={{ xs: 'none', md: 500 }}
            py={{ md: 6 }}
          >
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Solar Panels + Battery Storage
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              See how much you can save with a solar + battery system. Our advisors help you
              evaluate multiple bids, understand different technologies, and explore purchase and
              financing options. SVCE customers also qualify for an additional $1,000 rebate on a
              main electrical panel upgrade, if needed, when installing a solar and/or battery
              system through Solar + Battery Quotes.
            </Typography>
            <ButtonLink
              external
              href="https://solar.svcleanenergy.org/"
              sx={{
                width: { xs: '100%', md: 'auto' },
                alignSelf: 'flex-start',
                mb: { xs: 2, md: 4 },
              }}
            >
              Solar + Battery Quotes
            </ButtonLink>
            <Typography>
              Already have solar? Enhance your system with batteries to store excess energy for use
              during peak evening hours, helping you avoid higher electricity rates and providing
              backup power during outages.{' '}
              <Link external href="https://battery.svcleanenergy.org/">
                Visit Battery Quotes.
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} mb={{ xs: 2, md: 0 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={solarPanelsImage}
              alt="A house with solar panels"
              sx={{
                width: '100%',
                maxHeight: { xs: 250, md: 480 },
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 4, md: 3 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={evImage}
              alt="An electric vehicle charging"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" order={{ xs: 4, md: 3 }}>
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Drive Electric
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              Gas vehicles are the largest source of harmful emissions in Silicon Valley. Upgrading
              to an EV eliminates tailpipe emissions and can help you save money. Discover EV
              incentives, charging options, vehicles, and more.
            </Typography>
            <ButtonLink
              external
              href="https://ev.svcleanenergy.org/"
              sx={{
                alignSelf: 'flex-start',
                width: { xs: '100%', md: 'auto' },
                mb: { xs: 2, md: 0 },
              }}
            >
              EV Finder
            </ButtonLink>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          order={{ xs: 6, md: 5 }}
          mb={{ xs: 2, md: 0 }}
        >
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Electricity Rates
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              Once you've installed these efficient electric appliances, be sure to review your
              electricity rate options to maximize bill savings.
            </Typography>
            <ButtonLink
              external
              href="https://svcleanenergy.org/rates-and-billing/"
              sx={{
                width: { xs: '100%', md: 'auto' },
                alignSelf: 'flex-start',
              }}
            >
              Rates and Billing
            </ButtonLink>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 5, md: 6 }} mb={{ xs: 2, md: 0 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={computerImage}
              alt="A woman on a computer"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
