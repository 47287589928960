import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Link as InertiaLink } from '@inertiajs/react';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import ProductCard from '../ProductCard';

function ResourceDetailsApplicableProducts({ resource, clearDetailedItem }) {
  const { applicableProducts } = resource;
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={2.5}>
        Applicable products
      </Typography>
      <Grid container spacing={1}>
        {applicableProducts.map((product) => {
          return (
            <Grid item xs={12} sm={6} key={product.id}>
              <ProductCard
                product={product}
                disableCompare
                onClick={() => clearDetailedItem()}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default ResourceDetailsApplicableProducts;
