import { Box, Grid, Typography } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import ResourceList from './ResourceList';
import FilterRail from '../../../components/Filters/FilterRail';
import MobileFilters from '../../../components/Filters/MobileFilters';
import SvceButton from '../../../components/SvceButton';
import SpeakWithAnAdvisor from '../../../components/SpeakWithAnAdvisor';
import Breadcrumbs from '../../../components/Breadcrumbs';

export default function RecommendationsIndex({ filters_data: filtersData }) {
  return (
    <Box>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="flex-end"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={3}
      >
        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Box pb={2}>
          <Breadcrumbs />
        </Box>
        <HeadingWithFlair>Incentives Finder</HeadingWithFlair>
        <Typography>Get personalized recommendations based on your goals.</Typography>
      </Box>
      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4} xl={3} display={{ xs: 'none', md: 'flex' }}>
            <FilterRail
              title="Personalize Recommendations"
              description="Apply filters to see what options are best for you"
              filtersData={filtersData}
            />
          </Grid>

          <Grid item xs={12} md={8} xl={9}>
            <ResourceList />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '32px',
            mb: 2,
            display: { xs: 'none', sm: 'block' },
          }}
        >
          Assistants to help you and your family
        </Typography>
        <SpeakWithAnAdvisor />
      </Box>
    </Box>
  );
}
