import { Box, Button,  Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '../../assets/icons/icons';
import ProductBadge from '../ProductBadge';
import VisuallyHiddenText from '../VisuallyHiddenText';
import Link from '../Link';

// don't truncate description if clicking "Read more" will only reveal <50 more characters
const DESCRIPTION_CHAR_LIMIT = 150;
const DESCRIPTION_CHAR_LENGTH = 100;

const TruncatedDescription = ({ description, onReadMoreClick }) => (
  <>
    {description.slice(0, DESCRIPTION_CHAR_LENGTH) + '...'}
    <Button
      onClick={onReadMoreClick}
      variant="text"
      sx={{
        textTransform: 'none',
        textDecoration: 'underline',
        py: 0,
        px: 1,
        fontSize: { xs: '0.875rem', sm: '1rem' },
        verticalAlign: 'baseline',
        fontWeight: 600,
      }}
      aria-label="Expand product description"
    >
      Read more
    </Button>
  </>
);

export default function ProductDetailsHeader({ product, onDrawerClose }) {
  const {
    imageUrl,
    name,
    longDescription,
    badges,
    modelNumber,
    salePrice,
    regularPrice,
    isRebateEligible,
    rebate,
    rebateLink,
  } = product;

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const closeButtonRef = useRef(null);

  // send focus to the close button when the drawer opens
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box ml="auto" mb={1}>
        <Button
          ref={closeButtonRef}
          onClick={onDrawerClose}
          sx={{ minWidth: 'auto' }}
          aria-label="Close Product Details"
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box display="flex" mb={4} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={imageUrl}
            alt=""
            mr={7}
            mb={{ xs: 4, sm: 2 }}
            sx={{
              maxHeight: { xs: 125, sm: 182 },
              maxWidth: { xs: 125, sm: 182 },
              aspectRatio: 'auto',
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          {!!badges.length && (
            <Box
              alignSelf="flex-start"
              mb={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {badges.map((badge, idx) => (
                <Box key={badge} mb={idx === badges.length - 1 ? 0 : 0.5}>
                  <ProductBadge text={badge} />
                </Box>
              ))}
            </Box>
          )}
          <Typography
            id="product-drawer-header"
            variant="h2"
            fontWeight={600}
            fontSize="2rem"
            lineHeight="30px"
            mb={1.5}
          >
            {name}
          </Typography>
          <Typography variant="h4" component="span" mb={1.5} color="svce.darkGrey" fontSize="1rem">
            {modelNumber}
          </Typography>

          <Box display="flex" flexDirection="column">
            {!!regularPrice && regularPrice !== salePrice && (
              <>
                <VisuallyHiddenText>Regular price:</VisuallyHiddenText>
                <Typography
                  variant="h5"
                  fontSize="1rem"
                  component="span"
                  color="gray.600"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {regularPrice} full price
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <VisuallyHiddenText>Sale price:</VisuallyHiddenText>
            <Typography variant="h2" fontSize="2.25rem" component="span" fontWeight={600}>
              {salePrice}
            </Typography>
            {isRebateEligible && (
              <Link
                sx={{ ml: 2, textDecoration: 'underline', alignSelf: 'center' }}
                href={rebateLink}
              >
                <Typography fontSize="1rem" fontWeight={600}>
                  {rebate
                    ? 'See if you qualify for up to ' + rebate + ' Rebate'
                    : 'Rebate Eligible'}
                </Typography>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      <Typography
        color="gray.600"
        mb={2.5}
        sx={{ whiteSpace: 'pre-line' }}
        fontSize={{ xs: '0.875rem', sm: '1rem' }}
      >
        {isDescriptionExpanded || longDescription.length < DESCRIPTION_CHAR_LIMIT ? (
          longDescription
        ) : (
          <TruncatedDescription
            description={longDescription}
            onReadMoreClick={() => setIsDescriptionExpanded(true)}
          />
        )}
      </Typography>
    </Box>
  );
}
