import { Box, Grid, Typography } from '@mui/material';

export default function CompareProductSavings({ product }) {
  const { estimatedEnergySavings5Years, estimatedOperatingCost5Years } = product;

  return (
    <Box maxWidth={310}>
      {(estimatedEnergySavings5Years || estimatedOperatingCost5Years) && (
        <Typography variant="h4" component="h3" fontWeight="bold" fontSize="1.125rem" mb={2.5}>
          Estimated Costs &amp; Savings
        </Typography>
      )}

      <Grid container spacing={2} mb={6}>
        {estimatedOperatingCost5Years && (
          <Grid item xs={12}>
            <Box
              sx={{ backgroundColor: 'gray.200' }}
              py={2}
              px={3}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography mb={1.125} fontWeight={600}>
                Estimated Operating Costs Over 5 years
              </Typography>
              <Typography variant="h2" component="span" fontSize="2.25rem">
                {estimatedOperatingCost5Years}
              </Typography>
              <Typography mt={1.125} fontWeight={600} fontSize="1rem">
                * energy cost to run
              </Typography>
            </Box>
          </Grid>
        )}

        {estimatedEnergySavings5Years && (
          <Grid item xs={12}>
            <Box
              sx={{ backgroundColor: 'primary.main', color: 'common.white' }}
              py={2}
              px={3}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography mb={1.125} fontWeight={600}>
                Estimated Energy Cost Savings Over 5 Years
              </Typography>
              <Typography variant="h2" component="span" flexGrow={1} fontSize="2.25rem">
                {estimatedEnergySavings5Years}
              </Typography>
              <Typography mt={1.125} fontWeight={600} fontSize="1rem">
                * compared to avg model of same size + class
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
