import { router } from '@inertiajs/react';
import { Box, Tab, Tabs as MuiTabs, useTheme, Button } from '@mui/material';
import { RightArrowIcon } from '../../../assets/icons/icons';
import useAriaLiveStatus from '../../../hooks/useAriaLiveStatus';
import VisuallyHiddenText from '../../../components/VisuallyHiddenText';

const a11yProps = (index) => ({
  id: `tab-${index}`,
});

export default function CategoryTabs({ categories, selectedCategory }) {
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleCategoryChange = (categoryIdx) => {
    setAriaLiveStatus(`Showing top ${categories[categoryIdx].name}`);

    const newCategory = categories[categoryIdx];
    router.visit(newCategory.link, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const theme = useTheme();

  const handleLeftArrowClick = () => {
    handleCategoryChange(activeItemIdx === 0 ? categories.length - 1 : activeItemIdx - 1);
  };

  const handleRightArrowClick = () => {
    handleCategoryChange(activeItemIdx === categories.length - 1 ? 0 : activeItemIdx + 1);
  };

  const activeItemIdx = categories.findIndex(({ id }) => id === selectedCategory?.id);

  return (
    <Box display="flex" bgcolor="primary.main" borderRadius="8px">
      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
      <Button
        onClick={handleLeftArrowClick}
        sx={{
          pr: { xs: 0, sm: 2 },
          minWidth: 'auto',
        }}
        aria-label="Previous category"
      >
        <Box sx={{ transform: 'rotate(180deg)' }}>
          <RightArrowIcon fill={theme.palette.common.white} aria-hidden="true" focusable="false" />
        </Box>
      </Button>

      <MuiTabs
        value={activeItemIdx > -1 ? activeItemIdx : false}
        onChange={(_, newValue) => handleCategoryChange(newValue)}
        aria-label="Product categories"
        sx={{
          flexGrow: 1,
          minHeight: 'auto',
          '& .MuiTabs-scroller': {
            overflowX: 'scroll !important',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {categories.map(({ id, handle, name }, idx) => (
          <Tab
            key={id || handle}
            label={name}
            {...a11yProps(idx)}
            sx={{
              fontSize: '1rem',
              py: 1,
              px: 1.5,
              my: 0.5,
              mx: 0.25,
              textTransform: 'none',
              color: 'common.white',
              fontWeight: 600,
              minHeight: 'auto',
              '&.Mui-selected': {
                color: 'svce.codGray',
                backgroundColor: 'common.white',
                borderRadius: '6px',
                boxShadow: 1,
              },
              '&:focus-visible': {
                outline: '1px solid',
                outlineColor: 'primary.main',
                outlineOffset: -1,
              },
            }}
          />
        ))}
      </MuiTabs>

      <Button
        onClick={handleRightArrowClick}
        sx={{
          pl: { xs: 0, sm: 2 },
          minWidth: 'auto',
        }}
        aria-label="Next category"
      >
        <RightArrowIcon fill={theme.palette.common.white} aria-hidden="true" focusable="false" />
      </Button>
    </Box>
  );
}
