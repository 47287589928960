import { Box, Typography } from '@mui/material';
import CategoryGroup from './CategoryGroup';

export default function ProductCategories({ groupedCategories }) {
  return (
    <Box p={2}>
      <Box p={2}>
        <Typography component="h1" display="flex" flexDirection="column">
          <Typography
            variant="h4"
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            fontWeight="bold"
            textTransform="uppercase"
            textAlign="center"
            mb={{ xs: 2, md: 2.5 }}
          >
            Top Energy Products
          </Typography>
          <Typography
            variant="h2"
            fontWeight={400}
            fontSize="1.5rem"
            component="span"
            mb={2}
            textAlign="center"
          >
            Shop only the most energy efficient products in your price range.
          </Typography>
        </Typography>
        <Typography fontSize="0.875rem" fontWeight={600} color="primary.main" textAlign="center">
          All products are{' '}
          <Typography component="span" color="blue.500" fontSize="0.875rem" fontWeight={600}>
            ENERGY STAR®
          </Typography>{' '}
          certified where applicable.
        </Typography>
      </Box>

      <Box mt={3} mb={5}>
        {groupedCategories.map((group) => (
          <CategoryGroup categoryGroup={group} key={group.id} />
        ))}
      </Box>
    </Box>
  );
}
