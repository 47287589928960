import { createTheme, lighten } from '@mui/material/styles';
import ProximaNovaRegular from '../assets/fonts/ProximaNova-Regular.woff2';
import ProximaNovaRegularItalic from '../assets/fonts/ProximaNova-RegularItalic.woff2';
import ProximaNovaSemibold from '../assets/fonts/ProximaNova-Semibold.woff2';
import ProximaNovaSemiboldItalic from '../assets/fonts/ProximaNova-SemiboldItalic.woff2';
import ProximaNovaBold from '../assets/fonts/ProximaNova-Bold.woff2';
import ProximaNovaBoldItalic from '../assets/fonts/ProximaNova-BoldItalic.woff2';

const svceGreen = '#0EA28A';
const svceBlack = '#0a2333';
const svceWhite = '#FFF';
const svceDarkGreen = '#029984';
const svceDarkGrey = '#475467';

// Pulled out of Figma
const codGray = '#131313';
const turbine = svceGreen;
const gridGreen = '#70c165';
const gridGreenLight = '#F5FFF5';
const gridGreenDark = '#4e8746';
const teal = '#a3d9d6';
const orange = '#f68d37';
const darkBlue = '#003863';
const grey = '#a3a7a9';
const darkGrey = '#475467';
const cloudGrey = '#e4e4e4';

const nonSelfReferentialTheme = createTheme({
  palette: {
    primary: {
      main: svceGreen,
      light: svceWhite,
      dark: svceDarkGreen,
      contrastText: svceBlack,
    },
    secondary: {
      main: svceBlack,
      light: svceGreen,
      dark: svceDarkGreen,
      contrastText: svceWhite,
    },
    svce: {
      codGray,
      turbine,
      gridGreen,
      teal,
      orange,
      darkBlue,
      grey,
      darkGrey,
      cloudGrey,
    },
    gray: {
      100: '#F9FAFB',
      200: '#F1F1F1',
      250: '#F5F5F5',
      300: '#e0e0e0',
      600: '#666666',
      900: '#011627',
    },
    green: {
      100: '#ECFDF3',
      400: '#02ca9d',
      600: '#029875',
      700: '#027a48',
    },
    blue: {
      500: '#4dace9',
    },
  },
  typography: {
    fontFamily: 'Proxima Nova, gotham, sans-serif',
    h1: {
      fontSize: '48px',
      lineHeight: '108.333%',
      fontWeight: 600,
    },
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '18px',
      lineHeight: '1.5em',
      fontWeight: 400,
      color: svceBlack,
    },
  },
  shape: {
    svceBorderRadius: '50px',
    resourceCardBorderRadius: '4px',
  },
  boxShadows: {
    header: '0px 0px 16px rgba(140, 138, 136, 0.5), 8px 8px 32px rgba(89, 88, 87, 0.25)',
  },
});

export const theme = createTheme(nonSelfReferentialTheme, {
  palette: {
    svceGreen: nonSelfReferentialTheme.palette.augmentColor({
      color: {
        main: svceGreen,
        contrastText: svceWhite,
      },
      name: 'svceGreen',
    }),
    whiteButton: nonSelfReferentialTheme.palette.augmentColor({
      color: {
        main: svceWhite,
        light: lighten(svceGreen, 0.3),
        dark: lighten(svceGreen, 0.9),
        contrastText: svceGreen,
      },
      name: 'white',
    }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima Nova Regular'), url(${ProximaNovaRegular}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima Nova Regular Italic'), url(${ProximaNovaRegularItalic}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Proxima Nova Semibold'), url(${ProximaNovaSemibold}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 600;
          src: local('Proxima Nova Semibold Italic'), url(${ProximaNovaSemiboldItalic}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Proxima Nova Bold'), url(${ProximaNovaBold}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 700;
          src: local('Proxima Nova Bold Italic'), url(${ProximaNovaBoldItalic}) format('woff2');
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            fontSize: '1rem',
          },
          '&:focus-visible': {
            outline: '1px solid',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 500,
          '&.Mui-selected': {
            color: nonSelfReferentialTheme.palette.common.white,
          },
          '&:focus-visible': {
            outline: `1px solid ${nonSelfReferentialTheme.palette.common.black}`,
            '&.Mui-selected': {
              outlineOffset: '1px',
            },
          },
        },
      },
    },
  },
});
