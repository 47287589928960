import { useEffect, useState } from 'react';
import { Box, Typography, styled, Slider as MuiSlider } from '@mui/material';

const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  padding: '72px 0 3px 0',
  marginBottom: 0,
  '& .MuiSlider-rail': {
    height: 3,
    backgroundColor: theme.palette.grey[300],
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 3,
  },
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: theme.palette.primary.main,
    '&.Mui-focusVisible': {
      boxShadow: 'inherit',
      outline: `1px solid ${theme.palette.grey[600]}`,
      outlineOffset: 2,
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    height: 14,
    width: 14,
    opacity: 1,
    borderRadius: '50%',

    '&.MuiSlider-mark': {
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      transform: 'translate(-50%, -50%)',

      '&.MuiSlider-markActive': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSlider-markLabel': {
    top: 55,
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      maxWidth: 70,
      textWrap: 'pretty',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'none',
      textWrap: 'nowrap',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '0.875rem',
    background: 'unset',
    padding: 0,
    width: 35,
    height: 35,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    top: '-3px',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const getIdFromNumericValue = (marks, value) => marks.find((mark) => mark.value === value).id;
const getNumericValueFromId = (marks, id) => marks.find((mark) => mark.id === id).value;

export default function NonNumericSliderFilter({
  id,
  title,
  value: valueAsId,
  marks,
  onChange,
  step,
  ...rest
}) {
  const [workingNumericValue, setWorkingNumericValue] = useState(
    getNumericValueFromId(marks, valueAsId),
  );

  useEffect(() => {
    setWorkingNumericValue(getNumericValueFromId(marks, valueAsId));
  }, [valueAsId]);

  return (
    <Box mb={3} mt={title ? 0 : 3}>
      {title && (
        <Typography fontSize="1rem" component="h3" fontWeight={600} pt={4}>
          {title}
        </Typography>
      )}
      <Box px={2.5}>
        <StyledSlider
          value={workingNumericValue}
          step={null}
          valueLabelDisplay="off"
          onChange={(e, newNumericValue) => {
            setWorkingNumericValue(newNumericValue);
            if (!(e instanceof MouseEvent)) {
              const newValueId = getIdFromNumericValue(marks, newNumericValue);
              onChange(id, newValueId);
            }
          }}
          onChangeCommitted={(e) => {
            if (e instanceof MouseEvent) {
              const newValueId = getIdFromNumericValue(marks, workingNumericValue);
              onChange(id, newValueId);
            }
          }}
          marks={marks}
          {...rest}
        />
      </Box>
    </Box>
  );
}
