import { Box, Input } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { SearchIcon } from '../assets/icons/icons';
import { router, usePage } from '@inertiajs/react';
import useDebounce from '../hooks/useDebounce';
import { addQueryParam, removeQueryParam, getQueryParam } from '../utils/urlFunctions';
import VisuallyHiddenText from './VisuallyHiddenText';
import useAriaLiveStatus from '../hooks/useAriaLiveStatus';

const SearchBar = forwardRef(({ onFocus = () => {}, isInModal }, ref) => {
  const { url } = usePage();

  const [query, setQuery] = useState(getQueryParam(url, 'search') || '');
  // visually hidden search status message for screen readers
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const debouncedQuery = useDebounce(query, 750);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setAriaLiveStatus('Updating search results');
  };

  const handleSearch = (newQuery) => {
    let newUrl = removeQueryParam(url, 'search');
    if (newQuery.length > 1) {
      newUrl = addQueryParam(newUrl, 'search', newQuery);
    }
    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
      only: ['search_results'],
      onSuccess: () => setAriaLiveStatus('Search results updated'),
    });
  };

  useEffect(() => {
    handleSearch(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <>
      <Input
        ref={ref}
        value={query}
        onChange={handleInputChange}
        onFocus={onFocus}
        placeholder="Search eHub"
        disableUnderline
        data-testid="global-search"
        startAdornment={
          <Box mr={1} display="flex" alignItems="center" justifyContent="center">
            <SearchIcon />
          </Box>
        }
        sx={{
          height: 46,
          width: isInModal ? '100%' : { xs: 200, lg: 290 },
          backgroundColor: isInModal ? 'gray.100' : 'common.white',
          fontWeight: 600,
          py: 1.25,
          px: 1.75,
          borderRadius: '8px',
          mb: isInModal ? 2 : 0,
          mt: isInModal ? 1 : 0,
          border: '1px solid',
          borderColor: 'gray.300',
        }}
        inputProps={{
          'aria-label': 'Search eHub',
          sx: {
            p: 0,
          },
        }}
      />
      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
    </>
  );
});

// needed for arrow function declaration with forwardRef
// https://stackoverflow.com/questions/59716140/using-forwardref-with-proptypes-and-eslint
SearchBar.displayName = 'SearchBar';

export default SearchBar;
