import { usePage, router } from '@inertiajs/react';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { MinusIcon, PlusIcon } from '../../assets/icons/icons';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';
import VisuallyHiddenText from '../VisuallyHiddenText';

export default function ProductCompareButton({ product }) {
  const {
    props: { compare_products: compareProducts = [] },
  } = usePage();

  const theme = useTheme();

  const { id, categoryId } = product;
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleClick = (e) => {
    e.stopPropagation();
    router.post(
      `/compared_products`,
      { product_id: id },
      {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => setAriaLiveStatus(`Added ${product.name} to compare`),
      },
    );
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    router.delete(`/compared_products/${id}`, {
      preserveScroll: true,
      preserveState: true,
      onSuccess: () => setAriaLiveStatus(`Removed ${product.name} from compare`),
    });
  };

  const isSelected = compareProducts.find(({ id: product_id }) => product_id === id);
  const isDisabled =
    (compareProducts.length >= 3 && !isSelected) ||
    compareProducts.some(({ categoryId: compareCategoryId }) => categoryId !== compareCategoryId);

  if (isDisabled) return null;

  return (
    <>
      <Box
        position="absolute"
        component="button"
        onClick={isSelected ? handleDelete : handleClick}
        top={-1}
        right={-1}
        height={24}
        bgcolor={isSelected ? 'primary.main' : 'transparent'}
        border="1px solid"
        borderColor="primary.main"
        color="common.white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          px: 0,
          cursor: 'pointer',
          borderTopRightRadius: '4px',
          '&:hover': {
            backgroundColor: isSelected ? 'green.600' : 'gray.200',
            borderColor: 'green.600',
          },
        }}
      >
        <Typography
          color={isSelected ? 'common.white' : 'primary.main'}
          fontSize="0.875rem"
          fontWeight={700}
          pl={1}
        >
          Compare
        </Typography>
        <Box height={24} width={24} alignItems="center" justifyContent="center" display="flex">
          {isSelected ? <MinusIcon fill="#FFF" /> : <PlusIcon fill={theme.palette.primary.main} />}
        </Box>
      </Box>

      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
    </>
  );
}
