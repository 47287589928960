import { Typography } from '@mui/material';

export default function ProductBadge({ text }) {
  return (
    <Typography
      sx={{
        color: 'green.700',
        backgroundColor: 'green.100',
        px: 1,
        py: 0.75,
        borderRadius: 100,
        fontSize: '0.875rem',
        fontWeight: 'bold',
      }}
    >
      {text}
    </Typography>
  );
}
