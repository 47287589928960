import { Box, Button, Container, Grid, useTheme } from '@mui/material';
import { usePage } from '@inertiajs/react';
import { CloseIcon } from '../../assets/icons/icons';
import { forwardRef, useEffect } from 'react';
import SearchBar from '../SearchBar';
import Column from './Column';
import Products from './Products';
import ProductCategories from './ProductCategories';
import Resources from './Resources';

const SearchModal = forwardRef(({ isOpen, onClose }, ref) => {
  const theme = useTheme();

  // close on Esc press
  useEffect(() => {
    const closeModalOnEsc = (e) => {
      if (isOpen && e.key === 'Escape') {
        onClose(false);
      }
    };
    document.addEventListener('keydown', closeModalOnEsc);

    return () => document.removeEventListener('keydown', closeModalOnEsc);
  }, [isOpen]);

  const {
    props: {
      search_results: {
        is_default: isDefault,
        product_categories: productCategories,
        productsLink,
        products,
        resources,
      },
    },
  } = usePage();

  return (
    <Box
      data-testid="search-modal"
      ref={ref}
      display={isOpen ? 'flex' : 'none'}
      flexDirection="column"
      position="absolute"
      top={142}
      left={0}
      width="100%"
      bgcolor="common.white"
      boxShadow="0 6px 6px -3px rgba(0,0,0,0.2)"
      borderTop="1px solid"
      borderColor="gray.300"
      zIndex={theme.zIndex.modal}
      color="gray.900"
    >
      <Box ml="auto" pt={2} pr={2}>
        <Button onClick={onClose} aria-label="Close search menu">
          <CloseIcon />
        </Button>
      </Box>

      <Container>
        <Box display={{ xs: 'block', md: 'none' }}>
          <SearchBar isInModal />
        </Box>

        <Grid
          container
          mt={{ xs: 1, md: 4 }}
          mb={{ xs: 4, md: 8 }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          columnSpacing={3}
        >
          <Column
            header="Top Products"
            linkUrl={productsLink}
            handleLinkClick={onClose}
            showLink={isDefault || !!products.length}
          >
            {isDefault ? (
              <ProductCategories categories={productCategories} handleLinkClick={onClose} />
            ) : (
              <Products products={products} handleLinkClick={onClose} />
            )}
          </Column>

          <Column
            header="Top Resources"
            linkUrl="/incentives"
            handleLinkClick={onClose}
            showLink={!!resources.length}
          >
            <Resources resources={resources} handleLinkClick={onClose} />
          </Column>
        </Grid>
      </Container>
    </Box>
  );
});

// needed for arrow function declaration with forwardRef
// https://stackoverflow.com/questions/59716140/using-forwardref-with-proptypes-and-eslint
SearchModal.displayName = 'SearchModal';

export default SearchModal;
