import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';
import Link from '../../../components/Link';

export default function Lighting() {
  const {
    props: { family_image: familyImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Lighting & General Efficiency"
      img={familyImage}
      imgAlt="Image of a family"
      buttonLink="/products/light_bulbs"
      buttonText="Shop Lighting"
    >
      <Typography mb={2}>
        When you switch from gas to electric appliances you reduce emissions and may save money.
        Take one more step towards minimizing your energy usage: Install energy efficient LED light
        bulbs, weather stripping, insulation, and smart electronics, like thermostats and power
        strips.
      </Typography>
      <Typography>
        <Link href="/incentives" sx={{ color: 'green.400' }}>
          Learn more about maximizing energy efficiency in your home.
        </Link>
      </Typography>
    </ApplianceTab>
  );
}
