import { Box, Typography } from '@mui/material';
import SvceButton from './SvceButton';
import CityImage from '../assets/images/city-aerial.jpg';

export default function SpeakWithAnAdvisor() {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: `linear-gradient(0deg, rgba(0, 56, 99, 0.75) 0%, rgba(0, 56, 99, 0.75) 100%),
        url('${CityImage}') lightgray 50% / cover no-repeat`,
        display: 'flex',
        justifyContent: 'center',
        py: { xs: 3, md: 9 },
        px: { xs: 1, md: 9 },
        mb: { xs: 3, md: 0 },
      }}
    >
      <Box sx={{ maxWidth: '415px', textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: 'white', fontSize: '32px', fontWeight: 600 }}>
          Speak with an Advisor
        </Typography>
        <Typography sx={{ color: 'white', my: 2 }}>
          Our expert advisors are here to help you navigate rebates, find contractors, and create a
          personalized plan— for free!
        </Typography>
        <SvceButton
          href="https://goelectric.svcleanenergy.org/"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
          color="white"
        >
          Go Electric Advisor
        </SvceButton>
      </Box>
    </Box>
  );
}
