import { Box, Grid, Typography } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import CategoryTabs from './CategoryTabs';
import ProductList from './ProductList';
import FilterRail from '../../../components/Filters/FilterRail';
import ScrollToTop from '../../../components/ScrollToTop';
import CompareFooter from '../../../components/Compare/CompareFooter';
import MobileFilters from '../../../components/Filters/MobileFilters';
import Link from '../../../components/Link';
import { LeftArrowIcon } from '../../../assets/icons/icons';
import Breadcrumbs from '../../../components/Breadcrumbs';

export default function ProductsIndex({
  category,
  categories,
  products,
  sortType,
  sortOptions,
  pagination,
  countLabel,
  filters_data: filtersData,
}) {
  return (
    <Box>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={3}
      >
        <Link
          href="/product-categories"
          sx={{
            textDecoration: 'none',
            color: 'common.black',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box mr={1}>
            <LeftArrowIcon />
          </Box>
          <Typography
            variant="h4"
            component="p"
            fontWeight={700}
            fontSize="1rem"
            textTransform="uppercase"
          >
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Box pb={2}>
          <Breadcrumbs />
        </Box>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <HeadingWithFlair>Appliance Marketplace</HeadingWithFlair>
          <Typography fontSize="1.5rem" fontWeight={600}>
            Find the best products for you and your family
          </Typography>
          <Box width="100%" mt={4} mb={2}>
            <CategoryTabs selectedCategory={category} categories={categories} />
          </Box>
        </Box>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4} xl={3} display={{ xs: 'none', md: 'flex' }}>
            <FilterRail
              title="Top Energy Products"
              description={
                <>
                  <Typography mb={1}>
                    Shop only the most energy efficient products in your price range.
                  </Typography>
                  <Typography fontWeight={600} color="primary.main">
                    All products are{' '}
                    <Typography component="span" color="blue.500" fontWeight={600}>
                      ENERGY STAR®
                    </Typography>{' '}
                    certified where applicable.
                  </Typography>
                </>
              }
              filtersData={filtersData}
            />
          </Grid>

          <Grid item xs={12} md={8} xl={9}>
            <ProductList
              category={category}
              countLabel={countLabel}
              products={products}
              sortType={sortType}
              sortOptions={sortOptions}
              pagination={pagination}
            />
          </Grid>
        </Grid>
      </Box>

      <ScrollToTop />

      <CompareFooter />
    </Box>
  );
}
