import { Box } from '@mui/material';
import React, { Component } from 'react';
import ErrorPage from '../pages/ErrorPage/Index';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <ErrorPage error_message="Oops! Looks like something went wrong." />
        </Box>
      );
    }

    return this.props.children;
  }
}
