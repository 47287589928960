import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React from 'react';
import { DownArrowIcon } from '../assets/icons/icons';

export default function DropdownInput({
  id,
  label,
  ariaLabel,
  value,
  onChange,
  options,
  fullWidth,
  ...rest
}) {
  return (
    <Box {...rest} width={fullWidth ? '100%' : 'auto'}>
      <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box flexGrow={1}>
          {label && (
            <InputLabel
              hidden
              id={id}
              sx={{
                backgroundColor: '#FFF',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }}
            >
              {label}
            </InputLabel>
          )}
          <Select
            variant="standard"
            IconComponent={DownArrowIcon}
            disableUnderline
            labelId={label ? id : undefined}
            value={value}
            onChange={onChange}
            fullWidth
            aria-label={ariaLabel}
            SelectDisplayProps={{ 'aria-label': ariaLabel }}
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' },
              borderRadius: 0,
              fontWeight: 600,
              color: 'svce.black',
              backgroundColor: 'common.white',
              '& .MuiInput-input:focus': {
                backgroundColor: 'common.white',
              },
            }}
          >
            {options.map(({ value: val, children }) => (
              <MenuItem key={val} value={val}>
                {children}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
    </Box>
  );
}
