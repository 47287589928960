import { Box, useTheme } from '@mui/material';
import { Head, usePage } from '@inertiajs/react';
import Footer from './SvceLayout/Footer';
import ErrorBoundary from '../components/ErrorBoundary';
import Link from '../components/Link';
import Logo from '../assets/images/logo.png';
// import ErrorPageHeader from './ErrorPageHeader';

const ErrorLayout = ({ children }) => {
  const {
    props: { title, meta = [] },
  } = usePage();

  const theme = useTheme();

  return (
    <>
      <Head>
        {[
          title && <title key={title}>{title}</title>,
          ...meta.map(({ name, content }) => <meta key={name} name={name} content={content} />),
        ].filter((tag) => tag)}
      </Head>

      <Box
        sx={{
          backgroundColor: 'primary.main',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          component="header"
          sx={{
            backgroundColor: '#faf7f5',
            boxShadow: '0px 0px 16px rgba(140, 138, 136, 0.5), 8px 8px 32px rgba(89, 88, 87, 0.25)',
            py: 3,
            px: 4,
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Link href="/">
            <Box component="img" src={Logo} alt="SVCE Logo" height={46} width={210} />
          </Link>
        </Box>
        <Box sx={{ p: { xs: 1, sm: 3 } }} flexGrow={1}>
          <Box
            sx={{
              backgroundColor: 'common.white',
              borderRadius: theme.shape.svceBorderRadius,
            }}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

// eslint-disable-next-line react/display-name
export default (page) => <ErrorLayout>{page}</ErrorLayout>;
