import { Box, Typography } from '@mui/material';
import Link from '../Link';
import NoResults from './NoResults';

export default function Resources({ resources, handleLinkClick }) {
  if (!resources.length) {
    return <NoResults handleLinkClick={handleLinkClick} />;
  }

  return (
    <>
      {resources.map((resource) => (
        <Box key={resource.id} mb="18px" display="flex" alignItems="center">
          <Box
            height={72}
            width={72}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              alt={resource.name}
              src={resource.imageUrl}
              maxHeight={72}
              maxWidth={72}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" ml={2}>
            <Link
              href={`/incentives?detailed_item_id=${resource.id}&detailed_item_type=resource`}
              onClick={handleLinkClick}
            >
              <Typography
                variant="h4"
                component="span"
                color="blue.500"
                fontSize="1.125rem"
                fontWeight={600}
              >
                {resource.name}
              </Typography>
            </Link>
            <Typography color="gray.500">{resource.description}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}
