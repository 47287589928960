import { router, usePage } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { generateFiltersUrl, resetFiltersData } from '../../../utils/filterFunctions';
import { removeQueryParam, replaceQueryParam } from '../../../utils/urlFunctions';
import SvceButton from '../../../components/SvceButton';

function NoResults() {
  const {
    url,
    props: { filters_data: filtersData },
  } = usePage();

  const handleResetClick = () => {
    const resetFilters = resetFiltersData(filtersData);

    const filtersUrl = generateFiltersUrl(resetFilters);

    const newUrl = filtersUrl
      ? replaceQueryParam(url, 'filters', filtersUrl)
      : removeQueryParam(url, 'filters');

    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography
        variant="h3"
        textAlign="center"
        mb={3}
        p={3}
        fontSize={{ xs: '2rem', md: '3rem' }}
      >
        Sorry, we couldn&apos;t find any results that match your filters.
      </Typography>
      <SvceButton
        onClick={handleResetClick}
        sx={{
          px: 4,
          py: 1.5,
          fontWeight: 'bold',
        }}
      >
        Reset Filters
      </SvceButton>
    </Box>
  );
}

export default NoResults;
