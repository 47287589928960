import { useEffect, useState } from 'react';

// For a11y reasons, aria-live elements should reset their content to be blank ~5 seconds after it changes.
// This hook simply wraps a piece of state and resets its value 5 seconds after changing
export default function useAriaLiveStatus(initialValue = '') {
  const [ariaLiveStatus, setAriaLiveStatus] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => setAriaLiveStatus(''), 5000);
    return () => clearTimeout(timeout);
  }, [ariaLiveStatus]);

  return [ariaLiveStatus, setAriaLiveStatus];
}
